/* eslint-disable @typescript-eslint/naming-convention */
import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';

import { ClinicDirectus } from 'apiFolder/directus-main';

interface ITopPopular {
	clinic: ClinicDirectus;
	index: number;
	isTop?: boolean;
}

const ClinicTopPopular: React.FC<ITopPopular> = ({ clinic, index, isTop = false }) => {
	const { slug, name, images_data, full_address, website, clinic_phone_number, about_clinic } = clinic;
	return (
		<TopClinic>
			<TopBlock>
				<TopInfo>
					<ClinicName>
						{isTop && `${Number(index) + 1}. `}
						{name}
					</ClinicName>
					{images_data.logo && (
						<ClinicLogo>
							<img src={`${images_data.logo}?key=medium`} alt={images_data.logo_alt_text} />
						</ClinicLogo>
					)}
					<ClinicAddress>
						Address:
						<ClinicAddressContent> {full_address}</ClinicAddressContent>
					</ClinicAddress>
					<ClinicWebsite>
						Website:{' '}
						<a href={website} target='_blank' rel='noopener noreferrer'>
							{website}
						</a>
					</ClinicWebsite>
				</TopInfo>
				<TopTeamImg>
					{images_data.team_image && (
						<img src={`${images_data.team_image}?key=medium`} alt={images_data.team_image_alt_text} />
					)}
				</TopTeamImg>
			</TopBlock>
			<ClinicPhone>
				Phone number: <a href={`tel:+${clinic_phone_number}`}>{clinic_phone_number}</a>
			</ClinicPhone>
			<ClinicPhone>
				Clinic profile: <Link to={slug}>bookphysio.com{slug}/</Link>
			</ClinicPhone>
			{isTop && <ClinicAbout>{about_clinic}</ClinicAbout>}
		</TopClinic>
	);
};

const TopClinic = styled.div`
	border-bottom: 1px solid #e0e0df;
	padding-bottom: 50px;

	& a {
		transition: all 0.3s ease;
		&:hover {
			text-decoration: underline;
		}
	}
`;
const ClinicName = styled.h3`
	font-family: 'SharpGrotesk-MediumNo21', sans-serif;
	color: #051635;
	margin-bottom: 20px;
`;

const ClinicAddress = styled.p`
	color: #748095;
	margin-bottom: 20px;
`;
const ClinicAddressContent = styled.span`
	color: #758094;
	font-weight: 300;
`;

const ClinicWebsite = styled.p`
	color: #748095;
	margin-bottom: 20px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;

	a {
		color: #5b90d4;
		text-decoration: none;
	}
`;

const ClinicLogo = styled.div`
	position: relative;
	height: 40px;
	margin-bottom: 20px;
	font-size: 14px;

	& img {
		position: absolute;
		top: 0;
		left: 0;
		max-width: 100%;
		height: 100%;
		object-fit: contain;
	}
`;

const ClinicPhone = styled.p`
	color: #748095;
	margin-bottom: 20px;

	a {
		color: #5b90d4;
		text-decoration: none;
	}
`;
const ClinicAbout = styled.p`
	color: #748095;
	font-weight: 300;
	margin-bottom: 20px;
`;
const TopInfo = styled.div`
	flex: 1 1 auto;
`;
const TopBlock = styled.div`
	display: flex;
	margin-top: 50px;
	@media (max-width: 767px) {
		display: block;
	}
`;
const TopTeamImg = styled.div`
	flex: 0 0 200px;
	width: 200px;
	max-height: 120px;
	margin: 0 20px 0 30px;
	border-radius: 6px;
	position: relative;
	overflow: hidden;
	font-size: 14px;

	@media (max-width: 767px) {
		padding: 0 0 120px 0;
	}

	& img {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		object-fit: cover;
	}

	@media (max-width: 767px) {
		margin: 20px auto;
	}
`;
export default ClinicTopPopular;
