/* eslint-disable @typescript-eslint/naming-convention */
import React, { useState } from 'react';
import { Link } from 'gatsby';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import styled, { css } from 'styled-components';

import { IndexPageData, IPopular } from 'apiFolder/directus-main';

import { Content } from 'components/Content';
import { Accordion, AccordionDetails, AccordionSummary, Card, CardContent, Typography } from '@mui/material';
import aboutCitiesStaticData from 'staticData/aboutCities';
import ImageComponent from 'components/ImageComponent';
import ClinicTopPopular from 'components/booking/ClinicTopPopular';
// import defaultHero from 'images/physiotherapist.jpg';

interface Props {
	item: IndexPageData;
}

export interface IAboutCity {
	slug: string;
	location_image: string;
	location_alt_text: string;
}

const SeoBlock: React.FunctionComponent<Props> = ({ item }) => {
	// eslint-disable-next-line prettier/prettier
	const {
		name,
		about_bookphysio,
		top_clinics,
		faqs,
		posts,
		seopage_links,
		popular_locations,
		popular_clinics,
	} = item;

	const formatedPopularItems = (popularItems?: IPopular[]) => {
		if (!popularItems) return;
		return popularItems.map((popular) => ({ link: popular.slug, link_text: popular.name }));
	};

	const formatedPopularClinics = formatedPopularItems(popular_clinics);
	const formatedPopularLocations = formatedPopularItems(popular_locations);

	const popularClinics =
		formatedPopularClinics ??
		seopage_links.filter((word) => word.heading === 'Popular Clinics' || word.heading === 'Popular clinics');
	const popularLocations =
		formatedPopularLocations ?? seopage_links.filter((word) => word.heading === 'Popular Locations');
	const resources = seopage_links.filter((word) => word.heading === 'Resources');

	const date = new Date();
	const month = date.toLocaleString('default', { month: 'long' });
	const year = date.getFullYear();

	const navArray: string[] = [];
	if (about_bookphysio !== null && about_bookphysio !== '' && about_bookphysio !== undefined) {
		navArray.push('About BookPhysio.com');
	}
	if (top_clinics !== null && top_clinics !== undefined && top_clinics.clinics.length > 0) {
		navArray.push('Top 10 clinics');
	}
	if (faqs !== null && faqs !== undefined && faqs.length > 0) {
		navArray.push('FAQs');
	}
	if (posts !== null && posts !== undefined && posts.length > 0) {
		navArray.push('Posts');
	}
	if (popularClinics.length > 0) {
		navArray.push('Popular Clinics');
	}
	if (popularLocations.length > 0) {
		navArray.push('Popular Locations');
	}
	if (resources.length > 0) {
		navArray.push('Resources');
	}

	const NavArrayUniqValues = Array.from(new Set(navArray));

	if (NavArrayUniqValues.length === 0) {
		return <div style={{ paddingBottom: '50px' }}></div>;
	}

	const objectsNavArray = NavArrayUniqValues.map((navItem, index) => {
		if (index === 0) return { isActive: true, name: navItem };
		return {
			isActive: false,
			name: navItem,
		};
	});

	const [NavWords, setNavWords] = useState(objectsNavArray);
	const [state, setState] = useState(objectsNavArray[0].name);

	const city = [...aboutCitiesStaticData].find((cityItem) => cityItem.slug === `${item.slug}/`);

	const onNavChange = (tabItem: string) => {
		setState(tabItem);
		setNavWords(
			NavWords.map((m) => {
				m.isActive = m.name === tabItem;
				return m;
			}),
		);
	};

	return (
		<BlockStyled>
			<Content>
				<Inner>
					<Navigation>
						<NavigationItems>
							{NavWords.map((tabItem) => (
								<NavItem
									key={tabItem.name}
									isActive={tabItem.isActive}
									onClick={() => onNavChange(tabItem.name)}
								>
									<NavItemText>{tabItem.name}</NavItemText>
								</NavItem>
							))}
						</NavigationItems>
						<NavigationBorder />
					</Navigation>
					<div style={{ position: 'relative' }}>
						<TextPar isActive={state === 'Popular Locations'}>
							<Popular>
								{popularLocations.map((location) => (
									<PopularItem key={location.link_text}>
										<Link to={location.link}>{location.link_text}</Link>
									</PopularItem>
								))}
							</Popular>
						</TextPar>
						<TextPar isActive={state === 'Resources'}>
							<Popular>
								{resources.map((resource: any) => (
									<PopularItem key={resource.link_text}>
										<Link to={resource.link}>{resource.link_text}</Link>
									</PopularItem>
								))}
							</Popular>
						</TextPar>
						<TextPar isActive={state === 'About BookPhysio.com'}>
							<About>
								<AboutText>{about_bookphysio}</AboutText>
								{item.type === 'cities' && (
									<ImageBlock>
										<ImageComponent name={name} altText={city?.location_alt_text} />
									</ImageBlock>
								)}
							</About>
						</TextPar>
						<TextPar isActive={state === 'Top 10 clinics'}>
							<TopClinicsWrapper>
								{top_clinics && (
									<>
										<TopClinicsHeader>
											<TopClinicsTopHeading>{top_clinics?.h3}</TopClinicsTopHeading>
											<TopClinicsBottomHeading>
												{`Top 10 Physio clinics in ${name} based on our sorting system. Last updated ${month},
										${year}`}
											</TopClinicsBottomHeading>
										</TopClinicsHeader>
										{top_clinics?.clinics.map(
											(clinic, index: number) =>
												clinic !== null && (
													<ClinicTopPopular
														key={clinic.slug}
														clinic={clinic}
														index={index}
														isTop
													/>
												),
										)}
									</>
								)}
							</TopClinicsWrapper>
						</TextPar>
						<TextPar isActive={state === 'FAQs'}>
							<FaqWrapper>
								{faqs?.map((faqItem) => (
									<Accordion key={faqItem.question}>
										<AccordionSummary aria-controls='panel1a-content' id='panel1a-header'>
											<Typography>{faqItem.question}</Typography>
										</AccordionSummary>
										<AccordionDetails>
											<ReactMarkdownWrapper>
												<ReactMarkdown children={faqItem.answer} remarkPlugins={[remarkGfm]} />
											</ReactMarkdownWrapper>
										</AccordionDetails>
									</Accordion>
								))}
							</FaqWrapper>
						</TextPar>
						<TextPar isActive={state === 'Posts'}>
							<PostsWrapper>
								{posts?.map((post, index) => (
									<CardWrapper key={`${post.content}${index}`}>
										<Card style={{ height: '100%' }}>
											<CardContent>
												<Typography>{post.content}</Typography>
											</CardContent>
										</Card>
									</CardWrapper>
								))}
							</PostsWrapper>
						</TextPar>
						<TextPar isActive={state === 'Popular Clinics'}>
							<Popular>
								{popularClinics?.map((clin) => (
									<PopularItem key={clin.link}>
										<Link to={clin.link}>{clin.link_text}</Link>
									</PopularItem>
								))}
							</Popular>
						</TextPar>
					</div>
				</Inner>
			</Content>
		</BlockStyled>
	);
};
export const BlockStyled = styled.div`
	margin-bottom: 50px;
`;
export const Inner = styled.div``;
export const NavItem = styled.div<{ isActive: boolean }>`
	cursor: pointer;
	font-weight: 300;
	font-size: 16px;
	text-align: center;
	border-bottom: ${({ isActive }) => (isActive ? '2px solid black' : 'none')};
	color: ${({ isActive }) => (isActive ? '#000' : '#9a9999')};
	z-index: 2;
	margin-left: 70px;
	&:first-child {
		margin: 0;
	}

	@media (max-width: 586px) {
		margin-left: 40px;
	}
	@media (max-width: 465px) {
		margin-left: 15px;
	}
`;
export const NavItemText = styled.div`
	padding: 0 5px 10px;
	white-space: nowrap;
	font-weight: 400;
	@media (max-width: 465px) {
		font-size: 14px;
	}
`;
export const Navigation = styled.div`
	width: 100%;
	position: relative;
	display: flex;
	overflow: auto;
`;
export const NavigationItems = styled.div`
	flex: 1 1 100%;
	display: flex;
	// justify-content: space-between;
	overflow: auto;
`;

export const NavigationBorder = styled.div`
	border-bottom: 2px solid #f0f0f1;
	width: 100%;
	position: absolute;
	bottom: 0;
	z-index: 1;
`;

export const Popular = styled.div`
	padding-top: 30px;
	display: flex;
	font-weight: 300;
	color: #9a9999;
	flex-wrap: wrap;
	@media (max-width: 616px) {
		height: 100%;
	}
`;
export const PopularItem = styled.div`
	flex: 0 0 48%;
	padding-right: 20px;
	padding-bottom: 15px;
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;

	@media (max-width: 767px) {
		flex: 0 0 100%;
	}
	& a {
		color: #9a9999;
		text-decoration: none;
		transition: all 0.3s ease;
		text-transform: capitalize;

		&:hover {
			color: #424bec;
		}
	}
`;

export const Resources = styled.div`
	padding-top: 30px;
	display: flex;
	flex-direction: column;
	font-weight: 300;
	color: #9a9999;
	height: 170px;
	flex-wrap: wrap;
	@media (max-width: 708px) {
		height: 350px;
	}
`;
export const ResourcesItem = styled.div`
	padding-bottom: 15px;
`;

export const About = styled.div`
	display: flex;
	align-items: center;
	padding-top: 30px;

	padding-left: 5px;
	@media (max-width: 620px) {
		flex-wrap: wrap;
	}
`;
export const TopBlock = styled.div`
	display: flex;
	margin-top: 50px;
	@media (max-width: 767px) {
		display: block;
	}
`;
export const TopInfo = styled.div`
	flex: 1 1 auto;
`;
export const TopTeamImg = styled.div`
	flex: 0 0 200px;
	width: 200px;
	max-height: 120px;
	margin: 0 20px 0 30px;
	border-radius: 6px;
	position: relative;
	overflow: hidden;
	font-size: 14px;

	@media (max-width: 767px) {
		padding: 0 0 120px 0;
	}

	& img {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		object-fit: cover;
	}

	@media (max-width: 767px) {
		margin: 20px auto;
	}
`;
const ImageBlock = styled.div`
	margin-top: 30px;
	margin-left: 40px;
	flex: 0 0 240px;
	border-radius: 6px;
	overflow: hidden;
	@media (max-width: 620px) {
		margin: 30px auto 0;
	}
`;
export const AboutText = styled.p`
	flex: 0 1 580px;
	padding-top: 30px;
	display: flex;
	font-weight: 300;
	font-size: 16px;
	@media (max-width: 620px) {
		width: 100%;
	}
`;
export const AboutImg = styled.div`
	margin-left: 40px;
	@media (max-width: 767px) {
		margin-left: 0px;
	}
`;
export const TopClinicsWrapper = styled.div`
	padding: 0 10px;
`;
export const TopClinicsHeader = styled.div`
	margin: 100px auto 0;
	text-align: center;
	border-bottom: 1px solid #e0e0df;
	padding-bottom: 30px;
	@media (max-width: 768px) {
		margin: 40px auto 0;
		text-align: left;
	}
`;
export const TopClinicsTopHeading = styled.h2`
	font-family: 'SharpGrotesk-MediumNo21', sans-serif;
	font-size: 20px;
	color: #051634;
	margin-bottom: 20px;
	@media (max-width: 550px) {
		font-size: 18px;
	}
`;
export const TopClinicsBottomHeading = styled.p`
	color: #748093;
`;
export const TopClinic = styled.div`
	border-bottom: 1px solid #e0e0df;
	padding-bottom: 50px;

	& a {
		transition: all 0.3s ease;
		&:hover {
			text-decoration: underline;
		}
	}
`;
export const ClinicName = styled.h3`
	font-family: 'SharpGrotesk-MediumNo21', sans-serif;
	color: #051635;
	margin-bottom: 20px;
`;
export const ClinicLogo = styled.div`
	position: relative;
	height: 40px;
	margin-bottom: 20px;
	font-size: 14px;

	& img {
		position: absolute;
		top: 0;
		left: 0;
		max-width: 100%;
		height: 100%;
		object-fit: contain;
	}
`;
export const ClinicAddress = styled.p`
	color: #748095;
	margin-bottom: 20px;
`;
export const ClinicAddressContent = styled.span`
	color: #758094;
	font-weight: 300;
`;

export const ClinicWebsite = styled.p`
	color: #748095;
	margin-bottom: 20px;

	a {
		color: #5b90d4;
		text-decoration: none;
	}
`;
export const ClinicPhone = styled.p`
	color: #748095;
	margin-bottom: 20px;

	a {
		color: #5b90d4;
		text-decoration: none;
	}
`;
export const ClinicAbout = styled.p`
	color: #748095;
	font-weight: 300;
	margin-bottom: 20px;
`;

export const CardWrapper = styled.div`
	flex: 0 0 27%;
	margin: 30px 2.7% 0 2.7%;
	@media (max-width: 767px) {
		flex: 0 0 44%;
	}
	@media (max-width: 600px) {
		flex: 0 0 100%;
		margin: 30px 0;
	}
`;
export const PostsWrapper = styled.div`
	padding-top: 40px;
	display: flex;
	flex-wrap: wrap;
	margin: 0 -2.7%;
	@media (max-width: 767px) {
		justify-content: space-between;
	}
	@media (max-width: 600px) {
		margin: 0;
	}
`;
export const FaqWrapper = styled.div`
	margin: 80px auto 0;
	max-width: 900px;

	& p {
		text-overflow: ellipsis;
		overflow: hidden;
	}
`;
const TextPar = styled.div<{ isActive: boolean }>`
	transition: all 0.3s ease;

	${({ isActive }) =>
		!isActive &&
		css`
			display: none;
		`}

	${({ isActive }) =>
		isActive &&
		css`
			display: block;
			font-family: 'MuseoSans', sans-serif;
			font-style: normal;
			font-weight: 300;
			font-size: 16px;
			line-height: 23px;
			margin-top: 24px;
		`}
`;

export const ReactMarkdownWrapper = styled.div`
	margin: 0px;
	& p {
		font-family: 'MuseoSans', sans-serif;
		font-size: 16px;
		line-height: 23px;
		font-weight: 300;
		margin-bottom: 5px;
	}

	& h2 {
		font-weight: 600;
		font-size: 16px;
		line-height: 19px;
		color: #000000;
		margin: 20px 0;
	}

	& h3 {
		font-family: 'SharpGrotesk-MediumNo21', sans-serif;
		font-weight: 600;
		font-size: 16px;
		line-height: 19px;
		color: #000000;
		margin: 20px 0;
	}

	& h4 {
		font-family: 'SharpGrotesk-MediumNo21', sans-serif;
		font-weight: 600;
		font-size: 16px;
		line-height: 19px;
		color: #000000;
		margin: 20px 0;
	}

	& ul {
		margin: 10px 0 20px 20px;
	}
	// @media (max-width: 1052px) {
	// 	margin: 50px 0 70px;
	// }

	th {
		padding-top: 10px;
	}
	& td,
	th {
		padding-right: 10px;
		padding-bottom: 10px;
	}

	& a {
		color: inherit;
		transition: all 0.3s ease;

		&:hover {
			color: #424bec;
			text-decoration: none;
		}
	}
`;
export default SeoBlock;
