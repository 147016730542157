/* eslint-disable @typescript-eslint/naming-convention */
import React, { useState } from 'react';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';

import { IndexPageData } from 'apiFolder/directus-main';

import Seo from '../components/seo';
import Header from 'components/common/header/Header';
import ThemeProviderComponent from 'components/common/ThemeProvider';
import PaddingTopContainer from 'components/common/header/PaddingTopContainer';
import Footer from '../components/Footer/Footer';
import { Container } from 'components/Container';
import SearchingCopy from '../components/HomePage/Searching/SearchingCopy';
import PhysiotherapistCopy from '../components/HomePage/Physiotherapist/PhysiotherapistCopy';
import SeoBlock from '../components/SeoBlock/SeoBlock';
import { Content } from 'components/Content';

interface Context {
	pageContext: {
		item: IndexPageData;
		dataSuburbSlugs: string[];
	};
}

const IndexTemplate: React.FunctionComponent<Context> = ({ pageContext: { item, dataSuburbSlugs } }) => {
	const [isMenuOpen, toggleMenu] = useState(false);

	const {
		h1,
		h2,
		profile,
		meta_title,
		meta_description,
		sub_heading_text,
		tick_1,
		tick_2,
		tick_3,
		markup_schema,
	} = item;

	const toggleBurgerMenu = () => {
		toggleMenu(!isMenuOpen);
	};

	return (
		<>
			<Seo title={meta_title} description={meta_description} markupSchema={markup_schema} />
			<ThemeProviderComponent>
				<Header toggleBurgerMenu={toggleBurgerMenu} isBurgerOpen={isMenuOpen} />
				<PaddingTopContainer isBurgerOpen={isMenuOpen}>
					<Container>
						<SearchingCopy isOpen={isMenuOpen} header1={h1} existingPages={dataSuburbSlugs} />
						<PhysiotherapistCopy
							header2={h2}
							sub_heading_text={sub_heading_text}
							tick_1={tick_1}
							tick_2={tick_2}
							tick_3={tick_3}
						/>
						<SeoBlock item={item} />
						<Content>
							{profile ? (
								<div style={{ marginBottom: '100px' }}>
									<ReactMarkdown children={profile} remarkPlugins={[remarkGfm]} />
								</div>
							) : null}
						</Content>
					</Container>
					<Footer />
				</PaddingTopContainer>
			</ThemeProviderComponent>
		</>
	);
};

export default IndexTemplate;
