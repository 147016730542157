import sydney from 'images/cities/sydney.jpg';
import melbourne from 'images/cities/melbourne.jpg';
import perth from 'images/cities/perth.jpg';
import brisbane from 'images/cities/brisbane.jpg';
import adelaide from 'images/cities/adelaide.jpg';
import goldCoast from 'images/cities/gold-coast.jpg';
import canberra from 'images/cities/canberra.jpg';
import newcastle from 'images/cities/newcastle.jpg';
import centralCoast from 'images/cities/central-coast.jpg';
import sunshineCoast from 'images/cities/sunshine-coast.jpg';

export interface IAboutCity {
	slug: string;
	location_image: string;
	location_alt_text: string;
}

const aboutCities: IAboutCity[] = [
	{
		slug: '/sydney/',
		location_image: sydney,
		location_alt_text:
			'A kayaker is passing by while the sunset is on the Sydney, NSW skyline. Central Business District and the Opera House is in the background.',
	},
	{
		slug: '/melbourne/',
		location_image: melbourne,
		location_alt_text:
			'Pedestrians and cylers at the iconic Flinders Street Station in Melbourne, Victoria Australia',
	},
	{
		slug: '/perth/',
		location_image: perth,
		location_alt_text: 'Top view of Perth city and harbour from drone with blue sky - Western Australia',
	},
	{
		slug: '/brisbane/',
		location_image: brisbane,
		location_alt_text: 'View of skyscrapers from Kangaroo Point in Brisbane, Queensland, Australia',
	},
	{
		slug: '/adelaide/',
		location_image: adelaide,
		location_alt_text:
			'Old General Post Office building with modern office buildings behind on Victoria Square in Adelaide, South Australia',
	},
	{
		slug: '/gold-coast/',
		location_image: goldCoast,
		location_alt_text: 'Aerial view and city skyline of Gold Coast, Queensland, Australia',
	},
	{
		slug: '/canberra/',
		location_image: canberra,
		location_alt_text: 'Aerial view of Canberra, ACT from Belconnen in the morning - Australia',
	},
	{
		slug: '/newcastle/',
		location_image: newcastle,
		location_alt_text:
			'Panorama of Newcastle, NSW city on the hills over Hunter river and Pacific ocean with beaches, pool and surfers.',
	},
	{
		slug: '/central-coast/',
		location_image: centralCoast,
		location_alt_text:
			'Tuggerah lake flowing to Pacific ocean at The Entrance in the Central Coast, NSW - aerial landscape view.',
	},
	{
		slug: '/sunshine-coast/',
		location_image: sunshineCoast,
		location_alt_text: 'Aerial view over Kings Beach in Sunshine Coast, Queensland, Australia.',
	},
];

export default aboutCities;
