import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import styled from 'styled-components';

import { Content } from '../../Content';

interface Context {
	header2: string;
	sub_heading_text: string;
	tick_1: string;
	tick_2: string;
	tick_3: string;
}
const PhysiotherapistCopy: React.FunctionComponent<Context> = ({
	header2,
	sub_heading_text,
	tick_1,
	tick_2,
	tick_3,
}: any) => {
	return (
		<div>
			<PhysiotherapistStyled>
				<Content>
					<PhysiotherapistInner>
						<ImgBlock>
							<StaticImage
								src='../../../images/physiotherapist.jpg'
								alt='physiotherapist'
								width={506}
								height={327}
							/>
						</ImgBlock>
						<PhysiotherapistRight>
							<H2>{header2}</H2>
							<Paragraphs>
								<p>{sub_heading_text}</p>
							</Paragraphs>
							<List>
								<Item>
									<svg
										stroke='currentColor'
										fill='currentColor'
										strokeWidth='0'
										version='1'
										viewBox='0 0 48 48'
										enableBackground='new 0 0 48 48'
										height='1em'
										width='1em'
										xmlns='http://www.w3.org/2000/svg'
									>
										<polygon
											fill='#79d5b8'
											points='40.6,12.1 17,35.7 7.4,26.1 4.6,29 17,41.3 43.4,14.9'
										/>
									</svg>
									<LiText>{tick_1}</LiText>
								</Item>
								<Item>
									<svg
										stroke='currentColor'
										fill='currentColor'
										strokeWidth='0'
										version='1'
										viewBox='0 0 48 48'
										enableBackground='new 0 0 48 48'
										height='1em'
										width='1em'
										xmlns='http://www.w3.org/2000/svg'
									>
										<polygon
											fill='#79d5b8'
											points='40.6,12.1 17,35.7 7.4,26.1 4.6,29 17,41.3 43.4,14.9'
										/>
									</svg>

									<LiText>{tick_2}</LiText>
								</Item>
								<Item>
									<svg
										stroke='currentColor'
										fill='currentColor'
										strokeWidth='0'
										version='1'
										viewBox='0 0 48 48'
										enableBackground='new 0 0 48 48'
										height='1em'
										width='1em'
										xmlns='http://www.w3.org/2000/svg'
									>
										<polygon
											fill='#79d5b8'
											points='40.6,12.1 17,35.7 7.4,26.1 4.6,29 17,41.3 43.4,14.9'
										/>
									</svg>
									<LiText>{tick_3}</LiText>
								</Item>
							</List>
						</PhysiotherapistRight>
					</PhysiotherapistInner>
				</Content>
			</PhysiotherapistStyled>
		</div>
	);
};

const PhysiotherapistStyled = styled.div`
	width: 100%;
	background-color: #f7f8fa;
	padding-top: 67px;
	padding-bottom: 67px;
	margin-bottom: 64px;
	@media (max-width: 425px) {
		padding-top: 50px;
		padding-bottom: 45px;
		margin-bottom: 40px;
	}
`;
const PhysiotherapistInner = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	@media (max-width: 800px) {
		flex-direction: column;
	}
`;
const PhysiotherapistRight = styled.div`
	flex: 0 0 45.5%;
	margin-left: 64px;
	margin-top: 40px;
	margin-bottom: 40px;
	@media (max-width: 800px) {
		flex: 0 0 100%;
		margin-left: 0;
	}
`;
const H2 = styled.h2`
	font-family: 'SharpGrotesk-MediumNo21', sans-serif;
	line-height: 1.3;
	color: #152231;
	font-size: 30px;
	@media (max-width: 1024px) {
		font-size: 24px;
	}
	@media (max-width: 425px) {
		margin-top: 40px;
	}
`;
const Paragraphs = styled.div`
	margin: 17px 0 20px;
	font-weight: 500;
	font-size: 18px;
`;

const List = styled.ul`
	list-style: none;
`;
const Item = styled.li`
	margin-bottom: 9px;
`;

const LiText = styled.span`
	margin-left: 5px;
	font-weight: 300;
	font-size: 16px;
`;
const ImgBlock = styled.div`
	& img {
		border-radius: 6px;
	}
`;
export default PhysiotherapistCopy;
