import React from 'react';
import styled from 'styled-components';

import iconSrcInactive from 'images/icons/searchIconMain.svg';

const InputLoaderMain: React.FC = () => {
	return (
		<InputWrap>
			<Text>Search for an appointment by suburb</Text>
		</InputWrap>
	);
};

const InputWrap = styled.div`
	flex: 1 1 auto;
	width: 100%;
	height: 71px;
	display: flex;
	align-items: center;
	background: #ffffff;
	border: 2px solid #cdcdcd;
	box-sizing: border-box;
	border-radius: 3px;
	background-color: #ffffff;

	@media (max-width: 767px) {
		max-width: 382px;
		margin: 0 auto;
	}
`;

const Text = styled.div`
	font-family: MuseoSans;
	font-style: normal;
	font-weight: 300;
	font-size: 16px;
	line-height: 23px;
	color: #687684;

	display: flex;
	flex-wrap: nowrap;
	padding: 7px 30px 8px 52px;
	background: #ffffff;
	outline: none;
	boxshadow: none;

	position: relative;
	&:after {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		width: 14px;
		height: 100%;
		background: url(${iconSrcInactive}) no-repeat center / contain;
		background-size: 14px 14px;
		margin-left: 25px;
		margin-right: 10px;
	}
`;

export default InputLoaderMain;
