import React, { useEffect, useState } from 'react';
import { Content } from '../../Content';
import styled from 'styled-components';
import { Link, navigate } from 'gatsby';

import encodedUrl, { BookNowUrl } from 'helpers/encodedUrl';

import { USER_LOCATION_TEXT } from 'components/booking/Main';
import { IOption } from 'components/booking/HeaderTop';
import InputAutocomplete from './InputAutocomplete';
import LoaderDots from 'components/common/LoaderDots';

interface Context {
	header1: string;
	isOpen?: boolean;
	existingPages: string[];
}

export interface IGoogleValue {
	description: string;
	matched_substrings: [];
	place_id: string;
	reference: string;
	structured_formatting: { main_text: string; main_text_matched_substrings: []; secondary_text: [] };
	terms: { offset: number; value: string }[];
	types: string[];
}

const SearchingCopy: React.FC<Context> = ({ header1, isOpen, existingPages }) => {
	const [value, setValue] = useState<IOption | null>(null);

	useEffect(() => {
		if (value) {
			let staticUrl = existingPages.find((path: string) => path === (value.slug || value.urlSlug));

			if (!staticUrl) {
				staticUrl = setUrl(value);
			}

			// eslint-disable-next-line @typescript-eslint/no-floating-promises
			navigate(staticUrl);
		}
	}, [value]);

	const setUrl = (str: IOption) => {
		if (str && str.label === USER_LOCATION_TEXT) {
			return encodedUrl(str);
		}

		// redirect to book-now from main page
		if (str) {
			const state = `/${str.value!.terms[1]?.value.toLowerCase()}` || '';
			const suburbName = str.value!.terms[0].value.toLowerCase().replaceAll(' ', '-') as string;
			const postCode = str.label.replace(/[^0-9]/g, '');
			const suburbUrl = `${state}/${suburbName}-${postCode}`;
			const staticUrl = existingPages.find((path: string) => path === suburbUrl);

			return staticUrl ?? encodedUrl(str);
		}
		return `${BookNowUrl.url}/`;
	};

	return (
		<div>
			<SearchWrapper>
				<Content isOpen={isOpen}>
					<MainHeading>{header1}</MainHeading>
					<Search>
						<InputWrapper>
							<InputAutocomplete value={value} setValue={setValue} />
						</InputWrapper>
						<Link to={setUrl(value!)}>
							<Button disabled={!!value}>{value ? <LoaderDots /> : 'Show availabilities'}</Button>
						</Link>
					</Search>
				</Content>
			</SearchWrapper>
		</div>
	);
};

const SearchWrapper = styled.div`
	background-color: #fff;
	padding-bottom: 114px;
	@media (max-width: 768px) {
		padding-bottom: 50px;
	}
	@media (max-width: 425px) {
		padding-bottom: 70px;
	}
`;

const MainHeading = styled.h1`
	font-family: 'SharpGrotesk-MediumNo21', sans-serif;
	text-align: center;
	line-height: 43px;
	font-size: 34px;
	padding-top: 62px;
	margin-bottom: 42px;
	color: #152231;
	font-weight: normal;
	@media (max-width: 1024px) {
		font-size: 26px;
	}
	@media (max-width: 768px) {
		font-size: 20px;
	}
	@media (max-width: 425px) {
		padding-top: 59px;
		margin-bottom: 30px;
	}
`;
const Search = styled.div`
	margin: 0 auto;
	display: flex;
	justify-content: space-between;
	border: 1px solid #dde1e5;
	padding: 42px;
	border-radius: 4px;
	width: 980px;
	background-color: #fff;

	& > div {
		width: 100%;
	}
	& > div > div {
		width: 100%;
	}

	& a {
		text-decoration: none;
	}

	@media (max-width: 1024px) {
		width: 100%;
	}
	@media (max-width: 767px) {
		flex-direction: column;
		align-items: center;
		border: none;
	}
	@media (max-width: 500px) {
		padding: 0;
		& > a {
			width: 100%;
		}
	}
`;

const Button = styled.button`
	margin-left: 16px;
	background-color: #4543ec;
	color: white;
	border-radius: 4px;
	cursor: pointer;
	width: 310px;
	height: 70px;
	font-size: 17px;
	font-weight: 300;
	@media (max-width: 767px) {
		width: 382px;
		margin-left: 0;
		margin-top: 20px;
	}
	@media (max-width: 500px) {
		display: block;
		max-width: 382px;
		width: 100%;
		margin: 20px auto 0;
	}
`;

const InputWrapper = styled.div`
	position: relative;
	color: #848ea0;
	@media (max-width: 425px) {
		width: 100%;
	}
`;
export default SearchingCopy;
