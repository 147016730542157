/* eslint-disable @typescript-eslint/no-unsafe-return */
import React, { useRef, useState } from 'react';
import styled from 'styled-components';

import iconSrc from 'images/icons/searchIconPurp.svg';
import iconSrcInactive from 'images/icons/searchIconMain.svg';
import iconClose from 'images/icons/closeIcon.svg';
import { GoogleApiWrapper } from 'google-maps-react';
import GooglePlacesAutocomplete from 'components/common/googlePlacesAutocomplete/Autocomplete';
import InputLoaderMain from './InputLoaderMain';
import { IOption } from 'components/booking/HeaderTop';
import Geolocation from 'components/Geolocation';
import { USER_LOCATION_TEXT } from 'components/booking/Main';

interface IInput {
	value: IOption | null;
	setValue: (key: IOption | null) => void;
}

const InputAutocomplete: React.FC<IInput> = ({ value, setValue }) => {
	const [isOptionsClicked, setIsOptionsClicked] = useState<boolean>(false);
	const [isLocationAccepted, setIsLocationAccepted] = useState<boolean>(false);
	const mainAsyncRef = useRef(null);

	const customStyles = {
		container: (base: any) => ({
			...base,
			flex: '1 1 auto',
			width: '100%',
			height: 71,
			'@media (max-width: 767px)': {
				maxWidth: 382,
				margin: '0 auto',
			},
		}),
		control: (base: any) => ({
			...base,
			display: 'flex',
			flexWrap: 'nowrap',
			width: '100%',
			height: '100%',
			padding: '7px 0px 8px 44px',
			fontFamily: 'MuseoSans',
			background: '#FFFFFF',
			border: '2px solid #CDCDCD',
			borderRadius: 3,
			outline: 'none',
			fontSize: 16,
			fontWeight: 400,
			color: '#000000',
			transition: '0.3s ease-in',
			boxShadow: 'none',

			position: 'relative',
			'&:after': {
				content: "''",
				position: 'absolute',
				top: 0,
				left: 0,
				width: 14,
				height: '100%',
				background: `url(${iconSrcInactive}) no-repeat center / contain`,
				backgroundSize: '14px 14px',
				marginLeft: 25,
				marginRight: 14,
				cursor: 'pointer',
				transition: 'all 0.3s ease',
				'@media (max-width: 767px)': {
					marginRight: 17,
				},
			},

			'&:focus': {
				border: '2px solid #424BEC',
				boxShadow: '0px 0px 10px 2px rgb(66 75 236 / 18%)',
			},
			'&:hover': {
				border: '2px solid #424BEC',
				boxShadow: '0px 0px 10px 2px rgb(66 75 236 / 18%)',
			},
			'&:active': {
				border: '1px solid #CDCDCD',
				boxShadow: 'none',
			},
			'&:focus-within': {
				border: '1px solid #CDCDCD',
				boxShadow: '0px 0px 10px 2px rgb(66 75 236 / 18%)',
			},

			'&:focus-within, &:hover, &:focus': {
				'&:after': {
					top: 0,
					right: 0,
					background: `url(${iconSrc}) no-repeat center / contain`,
				},
				color: '#424BEC',
			},
		}),
		indicatorsContainer: (base: any) => ({
			...base,
			'& svg': {
				display: 'none',
			},
		}),
		indicatorSeparator: () => ({
			display: 'none',
		}),
		indicatorContainer: () => ({
			display: 'none',
		}),
		placeholder: (base: any) => ({
			...base,
			color: '#687684',
			fontSize: 16,
			fontWeight: 300,
		}),
		input: (base: any) => ({
			...base,
			cursor: 'text',
		}),
		valueContainer: (base: any) => ({
			...base,
			dislay: 'inline-flex',
			flex: '0 1 auto',
			paddingLeft: `${value ? '0' : '8px'}`,
		}),
		singleValue: (base: any) => ({
			...base,
			flexWrap: 'nowrap',
			backgroundColor: '#F8F8F8',
			padding: '8px 32px 8px 12px',
			borderRadius: '2px',
			minWidth: 146,
			maxWidth: 290,
			position: 'relative',
			top: 0,
			transform: 'translate(0)',
			fontSize: 14,
			fontWeight: 300,
			color: 'inherit',
			'&:after': {
				content: '""',
				position: 'absolute',
				top: 0,
				right: 10,
				width: 12,
				height: '100%',
				background: `url(${iconClose}) no-repeat center / contain`,
				cursor: 'pointer',
			},
			'@media (max-width: 413px)': {
				maxWidth: 250,
			},
			'@media (max-width: 374px)': {
				maxWidth: 210,
			},
		}),
		menu: (base: any) => ({
			...base,
			padding: '7px 10px 7px 22px',
			outline: 'none',
			fontSize: 16,
			fontWeight: 400,
		}),
		menuList: (base: any) => ({
			...base,

			'::-webkit-scrollbar': {
				width: '4px',
				height: '0px',
			},
			'::-webkit-scrollbar-track': {
				background: '#f1f1f1',
			},
			'::-webkit-scrollbar-thumb': {
				background: '#888',
			},
			'::-webkit-scrollbar-thumb:hover': {
				background: '#555',
			},
		}),
	};

	const setEmptyValue = () => {
		setValue(null);
	};

	const userLocation = (userCoord: { latitude: number; longitude: number }, userTimestamp: number) => {
		setValue({
			id: userTimestamp,
			name: USER_LOCATION_TEXT,
			label: USER_LOCATION_TEXT,
			location: {
				lat: userCoord.latitude,
				lng: userCoord.longitude,
			},
			state: '',
		});
	};

	const blurAsync = () => {
		mainAsyncRef.current?.blurInput();
	};

	return (
		<div>
			<GooglePlacesAutocomplete
				apiKey={process.env.GATSBY_GOOGLE_MAPS_API_KEY}
				selectProps={{
					ref: mainAsyncRef,
					value: value,
					onChange: setValue,
					onMenuOpen: setEmptyValue,
					placeholder: 'Search for an appointment by suburb',
					styles: customStyles,
					openMenuOnFocus: false,
					isClearable: true,
					autoFocus: !value,
					noOptionsMessage: () =>
						isOptionsClicked || isLocationAccepted ? (
							<Geolocation
								label='User current location'
								userLocation={userLocation}
								blurAsync={blurAsync}
								setIsLocationAccepted={setIsLocationAccepted}
							/>
						) : (
							<TextDefault onClick={() => setIsOptionsClicked(true)}>
								Permit to use current location
							</TextDefault>
						),
				}}
				autocompletionRequest={{
					bounds: [
						{ lat: -8.767933, lng: 111.793429 },
						{ lat: -43.63672, lng: 154.829255 },
					],
					types: ['(regions)'],
					componentRestrictions: {
						country: ['au'],
					},
				}}
				onLoadFailed={(error) => console.error('Could not inject Google script', error)}
			/>
		</div>
	);
};

const TextDefault = styled.p`
	color: inherit;
	&:hover {
		cursor: pointer;
	}
`;

export default GoogleApiWrapper({
	apiKey: process.env.GATSBY_GOOGLE_MAPS_API_KEY!,
	libraries: ['places'],
	LoadingContainer: InputLoaderMain,
})(InputAutocomplete);
