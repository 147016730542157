import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

interface IImage {
	name: string;
	altText?: string;
}

const ImageComponent: React.FC<IImage> = ({ name, altText }) => {
	const data = useStaticQuery(graphql`
		query {
			cityImage: allFile(filter: { relativeDirectory: { eq: "cities" } }) {
				edges {
					node {
						id
						name
						relativeDirectory
						relativePath
						publicURL
						childImageSharp {
							gatsbyImageData(height: 150, width: 240)
						}
					}
				}
			}
		}
	`);

	const images = data.cityImage.edges;
	const image = images.find((item) => item.node.name === name.toLowerCase().replaceAll(' ', '-'));
	const cityImage = image ? getImage(image.node.childImageSharp) : null;

	return cityImage ? <GatsbyImage image={cityImage} alt={altText!} /> : null;
};

export default ImageComponent;
